import React, { useEffect } from 'react';
import AOS from 'aos';
import AppRouter from './AppRouter';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Header = React.lazy(() => import('./Components/Header/Header'));
function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // values from 0 to 3000, with step 50ms
      once: true,     // whether animation should happen only once - while scrolling down
    });
  }, []);
  return (
    <>
    {/* <Header/> */}
    <AppRouter/>
    </>
  );
}

export default App;
