import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
const ReferralAgentSignUp = React.lazy(() =>
  import("./Pages/ReferralAgentSignUp")
);
const ReferralIncome = React.lazy(() => import("./Pages/ReferralIncome"));
const PersonalizedConsultation = React.lazy(() =>
  import("./Pages/PersonalizedConsultation")
);
const ContactUs = React.lazy(() => import("./Pages/ContactUs"));
const PartnerUs = React.lazy(() => import("./Pages/PartnerUs"));
const JoinUsLandingPage = React.lazy(() => import("./Pages/JoinUsLandingPage"));
const SubmitReferralLanding = React.lazy(() =>
  import("./Pages/SubmitReferralLanding")
);
const Home = React.lazy(() => import("./Pages/Home"));
const Aboutus = React.lazy(() => import("./Pages/Aboutus"));
const AgentLogin = React.lazy(() => import("./Pages/AgentLogin"));
const Faq = React.lazy(() => import("./Pages/Faq"));
const HowItWorks = React.lazy(() => import("./Pages/HowItWorks"));
const JoinToday = React.lazy(() => import("./Pages/JoinToday"));
const SubmitAReferral = React.lazy(() => import("./Pages/SubmitAReferral"));
const SuccessGuarantee = React.lazy(() => import("./Pages/SuccessGuarantee"));
const TrainingSupport = React.lazy(() => import("./Pages/TrainingSupport"));
const WebsitesMarketing = React.lazy(() => import("./Pages/WebsitesMarketing"));

const AppRouter = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <div className="spiner-set">
            <Spinner animation="border" size="lg" />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/agent-login" element={<AgentLogin />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/join-today" element={<JoinToday />} />
          <Route path="/submit-referral" element={<SubmitAReferral />} />
          <Route path="/success-guarantee" element={<SuccessGuarantee />} />
          <Route path="/training-support" element={<TrainingSupport />} />
          <Route path="/websites-marketing" element={<WebsitesMarketing />} />
          {/*new */}
          <Route path="/join-us" element={<JoinUsLandingPage />} />
          <Route
            path="/submit-referral-landing"
            element={<SubmitReferralLanding />}
          />
          <Route
            path="/referral-agent-signUp"
            element={<ReferralAgentSignUp />}
          />
          {/*  */}
          <Route path="/referral-income" element={<ReferralIncome />} />
          <Route
            path="/consultation-personalized"
            element={<PersonalizedConsultation />}
          />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/partner-us" element={<PartnerUs />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
